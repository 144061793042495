<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div style="float: right">
        <md-button class="md-warning" :disabled="isDisabled" @click="ExportDevices()">Export</md-button>
        <md-button v-if="loginUserEmail != 'apppcm1400@gmail.com'" class="md-success" :disabled="isDisabled" @click="SyncDevices()">
          Sync Devices<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
        <md-button v-if="loginUserDetail && loginUserDetail.tokenFileContent" class="md-secondary" :disabled="isDisabled" @click="syncChromeDevices()">
          Sync Chrome Devices<span v-if="isDisabled"><i class="fa fa-spinner fa-spin"></i></span></md-button>
      </div>
      <md-card>
        <md-card-content>
          <div class="md-layout">
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-select class="box-textbox" placeholder="Profile Name" v-model="search_profile_id">
                      <md-option value="">Profile</md-option>
                      <md-option v-for="profile in user_profiles" v-bind:key="profile._id" :value="profile._id">
                        {{ profile.profile_name }}
                      </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50">
                <md-field>
                  <md-input type="text" class="box-textbox" placeholder="Serial Number" v-model="search_serial_number"></md-input>
                </md-field>
              </div>
          </div>
          <div class="md-layout">
              <div class="md-layout-item md-size-33">
                <md-field>
                  <md-select class="box-textbox" placeholder="User" v-model="search_user_id">
                      <md-option value="">User</md-option>
                      <md-option v-for="user in application_users" v-bind:key="user._id" :value="user._id">
                        {{ user.user_name }}
                      </md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-33">
                  <md-switch v-model="search_hide_assign_devices">Hide Assign Devices</md-switch>
              </div>
              <div class="md-layout-item md-size-33">
                  <md-switch v-model="search_only_deleted_device">Only Deleted Devices</md-switch>
              </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <md-select class="box-textbox" placeholder="OS" v-model="search_device_type">
                    <md-option value="">OS</md-option>
                    <md-option value="Android">Android</md-option>
                    <md-option value="Ios">Ios</md-option>
                    <md-option value="Chromebook">Chromebook</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-size-50">
              <md-field>
                <md-input type="text" class="box-textbox" placeholder="IMEI" v-model="search_imei_number"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-50">
              <md-field>
                <md-input type="text" class="box-textbox" placeholder="Model" v-model="search_model"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
                <md-button class="md-primary" :disabled="isDisabled" @click="GetDeviceList()">Search</md-button> 
            </div>
          </div>
          <hr>
          <md-table 
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="md-container-custom paginated-table" table-header-color="green" md-fixed-header>
            <md-table-toolbar>
              <md-field>
                <label for="pages">Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Serial Number" md-sort-by="serialNumber">
                  <a v-if="item.type == 'Android'" @click="$router.push({ path: 'device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.serialNumber }}</a>
                  <a v-else-if="item.type == 'Ios'" @click="$router.push({ path: 'ios-device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.serialNumber }}</a>
                  <a v-else-if="item.type == 'Chromebook'" @click="$router.push({ path: 'chrome-device', query: { device_id: item._id }})" href="javascript:void(0)">{{ item.serialNumber }}</a>
                  <label v-if="item.deleted_at">
                    <span class="deleted">Deleted</span>
                    <br><p>{{ HumanReadableDateFormat(item.deleted_at, true) }}</p>
                  </label>
                </md-table-cell>
                <md-table-cell md-label="Profile">
                  <span v-if="item.profile_id">
                    <a v-if="item.type == 'Android'" @click="$router.push({ path: 'view-profiles', query: { profile_id: item.profile_id._id }})" href="javascript:void(0)">{{ item.profile_id.profile_name }}</a>
                    <a v-else-if="item.type == 'Ios'" @click="$router.push({ path: 'view-ios-profiles', query: { profile_id: item.profile_id._id }})" href="javascript:void(0)">{{ item.profile_id.profile_name }}</a>
                    <a v-else-if="item.type == 'Chromebook'" @click="$router.push({ path: 'view-chrome-profile', query: { profile_id: item.profile_id._id }})" href="javascript:void(0)">{{ item.profile_id.profile_name }}</a>

                    <p class="badge-blue" v-if="item.type == 'Android'">Android</p>
                    <p class="badge-red" v-else-if="item.type == 'Ios'">Ios</p>
                    <p class="badge-chrome-book" v-else-if="item.type == 'Chromebook'">Chromebook</p>
                  </span>
                  <span v-else>
                    -
                    <a v-if="item.type == 'Chromebook'" href="javascript:void(0);" @click="openAssignDeviceToProfileModal(item._id)">
                      <i class="fa fa-edit text-primary" aria-hidden="true"></i>
                    </a>
                    <p class="badge-blue" v-if="item.type == 'Android'">Android</p>
                    <p class="badge-red" v-else-if="item.type == 'Ios'">Ios</p>
                    <p class="badge-chrome-book" v-else-if="item.type == 'Chromebook'">
                      Chromebook
                    </p>
                  </span>
                  <p v-if="item.type == 'Chromebook' && item.json_response"><b>Org : </b> {{ item.json_response.orgUnitPath }} </p>
                </md-table-cell>
                <md-table-cell v-if="item.user_id" md-label="User Name">{{ item.user_id.user_name  }}
                  - <a href="javascript:void(0);" @click="DeleteDeviceUser(item._id)">
                      <i class="fa fa-trash text-danger" aria-hidden="true"></i>
                  </a>
                </md-table-cell>
                <md-table-cell v-else md-label="User Name">- 
                   <a href="javascript:void(0);" @click="OpenAddDeviceUserPopup(item._id)">
                      <i class="fa fa-edit text-primary" aria-hidden="true"></i>
                  </a>
                </md-table-cell>
                <!--<md-table-cell md-label="Hardware">{{ item.hardwareInfo.hardware }}</md-table-cell>
                <md-table-cell md-label="Brand">{{ item.hardwareInfo.brand }}</md-table-cell>-->
                
                <md-table-cell md-label="IMEI" v-if="item.json_response">
                  <span v-if="item.json_response.networkInfo">{{ item.json_response.networkInfo.imei }}</span>
                </md-table-cell>
                <md-table-cell md-label="IMEI" v-else>
                  -
                </md-table-cell>
                <md-table-cell md-label="Model">{{
                  item.hardwareInfo.model
                }}</md-table-cell>
                <md-table-cell md-label="Enrollment Time" md-sort-by="enrollmentTime">{{
                  HumanReadableDateFormat(item.enrollmentTime)
                }}</md-table-cell>
                <md-table-cell md-label="Last Sync" md-sort-by="lastPolicySyncTime">{{
                  HumanReadableDateFormat(item.lastPolicySyncTime)
                }}</md-table-cell>
                <md-table-cell md-label="Actions">
                  <div v-if="item.type == 'Ios'">
                    <md-button style="margin: unset;" class="md-just-icon md-danger md-simple" @click="DeleteIosDevice(item._id, item.profile_id._id)"><md-icon>close</md-icon></md-button>
                  </div>
                </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <!-- Models -->
      <template>
          <modal v-if="AddDeviceUserModal" @close="AddDeviceUserModal = false">
            <template slot="header">
              <h4 class="modal-title">Add Device User</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="AddDeviceUserModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <select style="width: 100%;border: unset;" placeholder="Search By User" v-model="add_user_id">
                          <option value="">Search By User</option>
                          <option v-for="user in application_users" v-bind:key="user.user_name" :value="user._id">
                            {{ user.user_name }} - {{ user.email }}
                          </option>
                      </select>
                    </md-field>
                  </div>
                </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="AddDeviceUserModal = false">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="AddDeviceUser()">Save</md-button>
            </template>
          </modal>
      </template>

      <template>
          <modal v-if="assignDeviceToProfileModal" @close="assignDeviceToProfileModal = false">
            <template slot="header">
              <h4 class="modal-title">Assign Profile</h4>
              <md-button
                style="height: 25px" class="md-simple md-just-icon md-round modal-default-button"
                @click="assignDeviceToProfileModal = false"><md-icon>clear</md-icon></md-button>
            </template>
            <template slot="body">
            <div class="box-body">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100">
                    <md-field>
                      <select style="width: 100%;border: unset;" placeholder="Select Profile" v-model="deviceProfileId">
                          <option value="">Select Profile</option>
                          <option v-for="profile in chromeProfiles" v-bind:key="profile._id" :value="profile._id">
                            {{ profile.profile_name }}
                          </option>
                      </select>
                    </md-field>
                  </div>
                </div>
            </div>
            </template>
            <template slot="footer">
              <md-button class="md-danger md-simple" @click="assignDeviceToProfileModal = false">Close</md-button>
              <md-button class="md-success" type="submit" :disabled="isDisabled" @click="updateDeviceProfile()">Save</md-button>
            </template>
          </modal>
      </template>
    </div>
  </div>
</template>
<script>
import DeviceProceDataService from "../../services/DeviceProceDataService";
import ApplicationUserDataService from "../../services/ApplicationUserDataService";
import QrCodeDataService from "../../services/QrCodeDataService";
import UserDataService from "../../services/UsersDataService";
import ChromeDataService from "../../services/ChromeDataService";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import { Pagination } from "@/components";
import { Modal } from "@/components";

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      isDisabled: false,
      loginUserEmail: "",
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      update_device_id: null,
      add_user_id: "",
      AddDeviceUserModal: false,
      tableData: [],

      //Search Parameters
      user_profiles: [],
      application_users: [],
      search_serial_number: "",
      search_profile_id: "",
      search_user_id: "",
      search_device_type: "",
      search_imei_number: "",
      search_model: "",
      search_hide_assign_devices: false,
      search_only_deleted_device: false,

      loginUserDetail:"",
      chromeProfiles:[],
      assignDeviceToProfileModal: false,
      deviceProfileId: "",

      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 200, 500],
        total: 0,
      },
      //Sorting
      currentSort: "serialNumber",
      currentSortOrder: "asc",
    };
  },
  methods: {
    
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },

    hasIMEI(object) {
        return this.containsKey(object, 'network');
    },

    containsKey(obj, key ) {
        return Object.keys(obj).includes(key);
    },

    GetApplicationUserList() {
      ApplicationUserDataService.GetApplicationUserList()
        .then((response) => {
          if (response.data.data) {
            this.application_users = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    GetDeviceProfileList() {
      DeviceProceDataService.GetDeviceProfileList()
        .then((response) => {
          if (response.data.data) {
            this.user_profiles = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    GetDeviceList() {
      let data = {
        profile_id: this.search_profile_id,
        serial_number: this.search_serial_number,
        user_id: this.search_user_id,
        hide_assign_devices: this.search_hide_assign_devices,
        type: this.search_device_type,
        imei_number: this.search_imei_number,
        model: this.search_model,
        only_deleted_devices : this.search_only_deleted_device
      }
      DeviceProceDataService.GetProfileDeviceList(data)
        .then((response) => {
            this.tableData = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    OpenAddDeviceUserPopup(device_id) {
      this.update_device_id = device_id;
      this.AddDeviceUserModal = true;
    },
    AddDeviceUser() {
      if (!this.update_device_id) {
          this.$toast.error("Error occur please try again.");
          this.AddDeviceUserModal = false;
          return;
      }
      if (!this.add_user_id) {
          this.$toast.error("Please select user.");
          return;
      }
      this.isDisabled = true;
      let data = {
        user_id: this.add_user_id,
        device_id: this.update_device_id
      }
      DeviceProceDataService.AddDeviceUser(data)
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.update_device_id = null;
          this.add_user_id = "";
          this.AddDeviceUserModal = false;
          this.GetDeviceList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.update_device_id = null,
          this.$toast.error(e.response.data.respones_msg);
        });
    },
    DeleteDeviceUser(device_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `User will be unlink from this device`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            device_id : device_id
          };
          DeviceProceDataService.DeleteDeviceUser(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetDeviceList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
          });
        }
      });  
    },
    SyncDevices() {
      this.isDisabled = true;
      DeviceProceDataService.SyncDevices()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    syncChromeDevices() {
      this.isDisabled = true;
      ChromeDataService.syncChromeDevices()
        .then((response) => {
          this.isDisabled = false;
          this.$toast.success(response.data.respones_msg);
          this.GetDeviceList();
        })
        .catch((e) => {
          this.isDisabled = false;
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    DeleteIosDevice(device_id, profile_id) {
      Swal.fire({
        title: "Are you sure?",
        text: `This device will be factory reseted`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Yes, delete it!",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var data = {
            profile_id : profile_id,
            device_id: device_id
          };
          DeviceProceDataService.DeleteIosDevice(data)
          .then((response) => {
            this.$toast.success(response.data.respones_msg);
            this.GetDeviceList();
          })
          .catch((e) => {
            this.$toast.error(e.response.data.respones_msg);
            this.GetDeviceList();
          });
        }
      });  
    },

    GetAdminDetail() {
      var data = {};
      UserDataService.getUserDetail(data)
        .then((response) => {
          this.loginUserEmail = response.data.data.email;
          this.loginUserDetail = response.data.data;
        }).catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    async ExportDevices() {
      if (this.tableData.length > 0) {
        let export_data = [];
        for await (let device of this.tableData) {
          let device_arr = [
            device.serialNumber, device.profile_id.profile_name,device.hardwareInfo.model, device.enrollmentTime
          ]
          export_data.push(device_arr);
        }
        this.DownloadSampleImportFile(export_data);
      } else {
        this.$toast.error("No data to export");
      }
    },
    
    DownloadSampleImportFile(export_data = "") {
      let csv = 'SerialNumber,ProfileName,Model,EnrollmentTime\n';
      if (export_data) {
        export_data.forEach((row) => {
            csv += row.join(',');
            csv += "\n";
        });
      }
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      if (export_data) {
        anchor.download = 'export-devices.csv';
      } else {
        anchor.download = 'import-user-sample.csv';
      }
      anchor.click();
    },

    getChromeProfiles() {
      DeviceProceDataService.GetDeviceProfileList({ search_profile_type: "Chromebook" })
        .then((response) => {
          if (response.data.data) {
            this.chromeProfiles = response.data.data;
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    },

    openAssignDeviceToProfileModal(device_id) {
      this.update_device_id = device_id;
      this.assignDeviceToProfileModal = true;
    },

    updateDeviceProfile() {
      let data = {
        id: this.update_device_id,
        profile_id: this.deviceProfileId
      }
      DeviceProceDataService.updateDeviceProfile(data)
        .then((response) => {
          this.$toast.success(response.data.respones_msg);
          this.assignDeviceToProfileModal = false;
          this.GetDeviceList();
        })
        .catch((e) => {
          this.$toast.error(e.response.data.respones_msg);
        });
    }

  },
  beforeMount() {
    this.GetDeviceList();
    this.GetDeviceProfileList();
    this.GetApplicationUserList();
    this.GetAdminDetail();
    this.getChromeProfiles();
  },
  mounted() {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["profile_id", "serialNumber", "ownership"],
      threshold: 0.3,
    });
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.tableData = result;
    },
  },
};
</script>
